import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dog from './Dog';
import { pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function DogsF() {

    const location = useLocation()

    const [key, setKey] = location.state ? useState(location.state.name) : useState('Scapa');

    return (
        <div className='container'>
            <Helmet>
                <title>Nos Femelles golden retriever</title>
                <meta
                    name="description"
                    content="Retrouvez les chiens de travail de l'élevage des Bruyères de Servière dans le Puy-de-dôme avec notamment leur pedigree et leur confirmation LOF"
                />
            </Helmet>
            <Tabs
                id="dogs-presentation"
                activeKey={key}
                onSelect={(d) => setKey(d)}
                className='mb-3'
                >
                    {[
                        'Scapa',
                        'Peat',
                        'Salou',
                        'Usher',
                        'Speyburn'
                    ].map((dog) => (
                        <Tab eventKey={dog} title={dog}>
                            <Dog dog={key}/>
                        </Tab>
                    ))}
            </Tabs>
        </div>
    );
}

export default DogsF;