import React from 'react';
import CarouselGolden from './CarouselGolden';
import { Row, Col, Carousel } from 'react-bootstrap';

function Dog({dog}) {

    const images = require.context('./img/golden/', true);
    const imageList = images.keys().filter(image => image.includes(dog)).map(image => images(image));

    const pdf = 'pdf/'+dog+'.pdf'

    var link = ""

    switch(dog){
        case "Scapa":
            link = "https://www.centrale-canine.fr/lofselect/chien/scapa-des-bruyeres-de-serviere-7792263";
            break;
        case "Laphroaig":
            link = "https://www.centrale-canine.fr/lofselect/chien/laphroaig-des-bruyeres-de-serviere-6335650";
            break;
        case "Peat":
            link = "https://www.centrale-canine.fr/lofselect/chien/peat-des-bruyeres-de-serviere-7362588"
            break;
        case "Salou":
            link ="https://www.centrale-canine.fr/lofselect/chien/salou-des-bruyeres-de-serviere-7792272"
            break;
        case "Usher":
            link ="https://www.centrale-canine.fr/lofselect/chien/usher-8344432"
            break;
        case "Speyburn":
            link = "https://www.centrale-canine.fr/lofselect/chien/speyburn-des-bruyeres-de-serviere-8008002"
            break;
        case "Tommy":
            link = "https://www.centrale-canine.fr/lofselect/chien/ticefields-man-about-town-8550403"
            break;
    }

    return (
        <div className='container'>
            {dog == 'Tommy' && <h1 className='mb-3'>{dog}</h1>
            || <h1 className='mb-3'>{dog} des Bruyères de Servière</h1>}
            <Row>
                {dog == 'Tommy' &&
                    <Col>
                        <div>
                            <p>
                                Tommy : Ticefields Man About Town <br/>
                                Né le 09 09 2022 <br/>
                                Hanches (Hips) A/A <br/>
                                Coudes (Elbows) 0/0 <br/>
                                Tares oculaires (eyes) ok le 24/09/2024 <br/>
                                Atrophie Rétinienne Progressive (PRCD) : indemne (free) <br/>
                                Atrophie Rétinienne Progressive (GR-PRA1) : indemne (free) <br/>
                                Atrophie Rétinienne Progressive (GR-PRA2) : indemne (free) <br/>
                                Myélopathie dégénérative type A  (DM1A) : indemne (free) <br/>
                                Dystrophie Musculaire Type Duchenne (GRMD) : indemne (free) <br/>
                                Ostéogenèse Imparfaite (OI) : Indemne (free) <br/>
                                Ceroide Lipofuscinose Neuronale 5 (NCL5-GR) : indemne (free) <br/>
                                Neuropathie Ataxique Sensitive (SAN) : indemne (free) <br/>
                                Epidermolyse Bulleuse - Golden Retriever (JEB-GR) : indemne (free) <br/>
                                Ichtyose Type 2 (ICH2) : indemne (free) <br/>
                                Myasthenie Congenitale - Golden Retriever (CMS-GR) : indemne (free) <br/>
                                Malformations Oculaires Congenitales (CEM) : indemne (free) <br/>
                                Locus C Curl (LocC) : indemne (free) <br/>
                            </p>
                        </div>
                        <ul>
                            <li>
                                <a href="pdf/ADN_Tommy.pdf" target="_blank" rel="noopener noreferrer">
                                    ADN de {dog}
                                </a>
                            </li>
                            <li>
                                <a href="pdf/HC_Tommy.pdf" target="_blank" rel="noopener noreferrer">
                                    Hanches et coudes de {dog}
                                </a>
                            </li>
                            <li>
                                <a href="pdf/TO_Tommy.pdf" target="_blank" rel="noopener noreferrer">
                                    Tares oculaires de {dog}
                                </a>
                            </li>
                        </ul>
                        <a href={pdf} target="_blank" rel="noopener noreferrer">
                            Pedigree de {dog}
                        </a>
                        <p>
                            Retrouver son pedigree complet sur <a href={link}>la centrale canine</a>
                        </p>
                    </Col>
                || dog == 'Speyburn' &&
                    <Col>
                        <div>
                            <p>
                                Spey : Speyburn des Bruyères de Servière <br/>
                                Née le 20 11 2021 <br/>
                                Hanches (Hips) A/A <br/>
                                Coudes (Elbows) 0/0 <br/>
                                Atrophie Rétinienne Progressive (PRCD) : indemne (free) <br/>
                                Atrophie Rétinienne Progressive (GR-PRA1) : indemne (free) <br/>
                                Atrophie Rétinienne Progressive (GR-PRA2) : indemne (free) <br/>
                                Myélopathie dégénérative type A  (DM1A) : indemne (free) <br/>
                                Dystrophie Musculaire Type Duchenne (GRMD) : indemne (free) <br/>
                                Ostéogenèse Imparfaite (OI) : Indemne (free) <br/>
                                Ceroide Lipofuscinose Neuronale 5 (NCL5-GR) : indemne (free) <br/>
                                Neuropathie Ataxique Sensitive (SAN) : indemne (free) <br/>
                                Epidermolyse Bulleuse - Golden Retriever (JEB-GR) : indemne (free) <br/>
                                Ichtyose Type 2 (ICH2) : indemne (free) <br/>
                                Myasthenie Congenitale - Golden Retriever (CMS-GR) : indemne (free) <br/>
                                Malformations Oculaires Congenitales (CEM) : indemne (free) <br/>
                                Locus C Curl (LocC) : indemne (free) <br/>
                            </p>
                        </div>
                        <ul>
                            <li>
                                <a href="pdf/ADN_Speyburn.pdf" target="_blank" rel="noopener noreferrer">
                                    ADN de {dog}
                                </a>
                            </li>
                            <li>
                                <a href="pdf/HC_Speyburn.pdf" target="_blank" rel="noopener noreferrer">
                                    Hanches et coudes de {dog}
                                </a>
                            </li>
                        </ul>
                        <a href={pdf} target="_blank" rel="noopener noreferrer">
                            Pedigree de {dog}
                        </a>
                        <p>
                            Retrouver son pedigree complet sur <a href={link}>la centrale canine</a>
                        </p>
                    </Col>
                ||
                    <Col>
                        <a href={pdf} target="_blank" rel="noopener noreferrer">
                            Pedigree de {dog}
                        </a>
                        <p>
                            Retrouver son pedigree complet sur <a href={link}>la centrale canine</a>
                        </p>
                    </Col>
                }
                <Col>
                    {/*<CarouselGolden imageList={imageList} />*/}
                    <div className='d-flex justify-content-center mt-3'>
                        <Carousel fade>
                            {imageList.map((image) => (
                                <Carousel.Item>
                                    <img loading='lazy' className="d-block w-100" style={{objectFit:"contain"}} src={image} alt="Elevage de golden retriever"/>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </div>
    );

};

export default Dog;
