import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p className="text-center fw-light">
          Copyright © 2025 Les Bruyeres de Serviere
          <br />
          All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;